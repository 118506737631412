import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {


  private secretKey: string = 'abcdef0123456789abcdef0123456789'; // Replace with a strong key
  private iv: string = '789abcdef0123456'; // Use a fixed IV for deterministic results

  constructor() {}

  // Encrypt a string
  encrypt(value: string|null): string {
    try{

      if(value==null || value ==undefined)
        return 'null';
      const encrypted = CryptoJS.AES.encrypt(value, CryptoJS.enc.Utf8.parse(this.secretKey), {
        iv: CryptoJS.enc.Utf8.parse(this.iv),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      });
      return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }catch(error){
      return value??'';
    }
  }

  // Decrypt a string
  decrypt(encryptedValue: string|null): string {

    if(!encryptedValue)
      return 'null';
    try {
      const decrypted = CryptoJS.AES.decrypt(
        {
          ciphertext: CryptoJS.enc.Base64.parse(encryptedValue), // Parse Base64 ciphertext
        } as CryptoJS.lib.CipherParams, // Specify the ciphertext input type
        CryptoJS.enc.Utf8.parse(this.secretKey),
        {
          iv: CryptoJS.enc.Utf8.parse(this.iv),
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC,
        }
      );

      const plaintext = decrypted.toString(CryptoJS.enc.Utf8);
      if (!plaintext) {
        throw new Error('Decryption resulted in an empty string.');
      }

      return plaintext;
    } catch (error) {
      console.log('Decryption error:', error);
      return 'null';
      // throw new Error('Failed to decrypt the value. Please check the key and input.');
    }
  }
}

import {
  ApplicationConfig,
  TransferState,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';


import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { translateBrowserLoaderFactory } from './core/loaders/translate-browser.loader';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
} from '@gilsdav/ngx-translate-router';
import { localizeBrowserLoaderFactory } from './core/loaders/localize-browser.loader';
import { APP_BASE_HREF, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MessagesService } from './core/services/messages/messages.service';
import { MetaService } from './core/services/meta/meta.service';
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { TokenInterceptor } from './core/interceptor/token/token.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthService } from './ero-erp/features/auth/services/auth.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(MatDialogModule),
    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      })
    ),
    provideAnimations(),
    importProvidersFrom(MatSnackBarModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translateBrowserLoaderFactory,
          deps: [
            HttpClient, TransferState
          ],
        },
      })
    ),
    importProvidersFrom(
      LocalizeRouterModule.forRoot(routes, {
        parser: {
          provide: LocalizeParser,
          useFactory: localizeBrowserLoaderFactory,
          deps: [
            TranslateService,
          Location,
            LocalizeRouterSettings,
            HttpClient,
            TransferState,
          ],
        },
        initialNavigation:true,
        defaultLangFunction: (
          languages: string[],
          cachedLang?: string,
          browserLang?: string
        ): any => {
          let lang = cachedLang || languages[0] || browserLang || 'en';
          return lang;
        },
      })
    ),
    MessagesService,
    MetaService,
    AuthService,
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    provideAnimations(),
  ],
};
